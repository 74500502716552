<template lang="pug">
div
	swiper-container(
		ref='carouselElement',
		:class='carouselClass',
		:direction='carouselDirection',
		:space-between='carouselItemGap',
		:loop='loop',
		slides-per-view='auto',
		@swipersnapindexchange='slideChange'
	)
		slot(:carouselItemProps='itemProps')
</template>

<script lang="ts" setup>
import type { SwiperContainer } from 'swiper/element';

/**
 * Carousel element
 */
const carouselElement = ref<SwiperContainer | null>(null);

const {
	// state
	initialize,
	activeIndex,
	activeSlide,
	isEndSlide,
	isTouched,

	// methods
	slidePrev,
	slideNext,
	slideTo,
	slideReset,
	slideZoomIn,
	slideZoomOut,
	slideChange,
	slideUpdate,
	sliderReset,
} = useCarousel(carouselElement);

/**
 * Carousel types
 */
type Gap = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

/**
 * Define props
 */
const {
	vertical = false,
	gap = 'md',
	perView = 1,
	loop = false,
} = defineProps<{
	vertical?: boolean;
	gap?: Gap;
	perView?: number;
	loop?: boolean;
}>();

/**
 * Define emits
 */
const emit = defineEmits<{
	(event: 'slideChange', index: number): void;
}>();

/**
 * Computed carousel direction
 */
const carouselDirection = computed(() => {
	return vertical ? 'vertical' : 'horizontal';
});

/**
 * Computed carousel classes
 */
const carouselBaseClass = 'Carousel';
const carouselClass = computed(() => {
	const classes = [
		carouselBaseClass,
		...(vertical ? [`${carouselBaseClass}--vertical`] : [`${carouselBaseClass}--horizontal`]),
		...(gap && [`${carouselBaseClass}--gap-${gap}`]),
	];

	return classes.join(' ');
});

/**
 * Computed carousel item gap
 */
const carouselItemGap = computed(() => {
	const gapSizes: Record<Gap, number> = {
		xs: 5,
		sm: 10,
		md: 20,
		lg: 35,
		xl: 50,
	};

	return gapSizes[gap] || gapSizes.md;
});

/**
 * Computed carousel item props
 */
const carouselItemBaseClass = 'CarouselItem';
const carouselItemClass = computed(() => {
	const classes = [carouselItemBaseClass];

	return classes.join(' ');
});

const itemProps = computed(() => {
	return {
		class: carouselItemClass.value,
		style: vertical
			? {
					height: `calc((100% / ${perView}) - (${carouselItemGap.value}px - (${carouselItemGap.value}px / ${perView})))`, // TODO: fix calc
					margin: `0 0 ${carouselItemGap.value}px 0`,
				}
			: {
					width: `calc((100% / ${perView}) - (${carouselItemGap.value}px - (${carouselItemGap.value}px / ${perView})))`, // TODO: fix calc
					margin: `0 ${carouselItemGap.value}px 0 0`,
				},
	};
});

/** Reset slider after change props */
watch(
	() => [vertical, gap, perView, loop],
	() => {
		sliderReset();
	}
);

/**
 * Define exposed
 */
defineExpose({
	// state
	initialize,
	activeIndex,
	activeSlide,
	isEndSlide,
	isTouched,

	// methods
	slidePrev,
	slideNext,
	slideTo,
	slideReset,
	slideZoomIn,
	slideZoomOut,
	slideChange,
	slideUpdate,
	sliderReset,
});
</script>

<!-- Swiper styles-->
<style>
swiper-container::part(container) {
	overflow: visible;
}
</style>

<!-- Carousel styles-->
<style lang="less">
.Carousel {
	.relative(1; visible);

	&--horizontal {
		.box(flex; 100%; 100%; none; stretch; stretch; stretch; nowrap row);

		& .CarouselItem {
			.flex(0 0 auto);
			.box(block; 100%; auto; none);
			&:last-child {
				margin: 0 !important;
			}
		}
	}
	&--vertical {
		.box(flex; 100%; 100%; none; stretch; stretch; stretch; nowrap column);

		& .CarouselItem {
			.flex(0 0 auto);
			.box(block; 100%; 100%; none);
			&:last-child {
				margin: 0 !important;
			}
		}
	}
}
</style>
