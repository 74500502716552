import type { Swiper } from 'swiper/types';
import type { SwiperContainer } from 'swiper/element';
import { register } from 'swiper/element/bundle';
import 'swiper/css/bundle';

export const useCarousel = (swiperContainerEl: Ref<SwiperContainer | null> = ref(null)) => {
	const initialize = ref<boolean>(false);
	const activeIndex = ref<number>(0);
	const activeSlide = ref<number>(1);
	const isEndSlide = ref<boolean>(false);
	const isTouched = ref<boolean>(false);

	onMounted(() => {
		register();

		if (swiperContainerEl.value) {
			activeIndex.value = swiperContainerEl.value.swiper?.activeIndex || 0;

			initialize.value = true;
		}
	});

	/**
	 * Run transition to previous slide.
	 */
	const slidePrev = (...params: Parameters<Swiper['slidePrev']>) => {
		swiperContainerEl.value?.swiper.slidePrev(...params);
	};

	/**
	 * Run transition to next slide.
	 */
	const slideNext = (...params: Parameters<Swiper['slideNext']>) => {
		swiperContainerEl.value?.swiper.slideNext(...params);
	};

	/**
	 * Run transition to slide with index number
	 */
	const slideTo = (...params: Parameters<Swiper['slideTo']>) => {
		swiperContainerEl.value?.swiper.slideTo(...params);
	};

	/**
	 * Reset swiper position to currently active slide
	 */
	const slideReset = (...params: Parameters<Swiper['slideReset']>) => {
		swiperContainerEl.value?.swiper.slideReset(...params);
	};

	/**
	 * Current slide zoom in
	 */
	const slideZoomIn = (ratio: number) => {
		swiperContainerEl.value?.swiper.zoom.in(ratio);
	};

	/**
	 * Current slide zoom out
	 */
	const slideZoomOut = () => {
		swiperContainerEl.value?.swiper.zoom.out();
	};

	/**
	 * Slider active index
	 */
	const slideChange = () => {
		nextTick(() => {
			activeIndex.value =
				(swiperContainerEl.value?.swiper.isEnd
					? swiperContainerEl.value?.swiper.snapIndex
					: swiperContainerEl.value?.swiper.realIndex) || 0;
			activeSlide.value = activeIndex.value + 1;
			isEndSlide.value = Boolean(swiperContainerEl.value?.swiper.isEnd);
		});
	};

	/**
	 * Set slider touched state
	 */
	const slideUpdate = () => {
		if (initialize.value && !isTouched.value) {
			isTouched.value = true;
		}
	};

	/** Reset slider */
	const sliderReset = () => {
		nextTick(() => {
			swiperContainerEl.value?.swiper.update();
			swiperContainerEl.value?.swiper.updateSlides();
		});
	};

	return {
		// state
		initialize,
		activeIndex,
		activeSlide,
		isEndSlide,
		isTouched,

		// methods
		slidePrev,
		slideNext,
		slideTo,
		slideReset,
		slideZoomIn,
		slideZoomOut,
		slideChange,
		slideUpdate,
		sliderReset,
	};
};
